<template>
  <div>
    <template v-if="authShareholder && authShareholder.company && authShareholder.company.id">
      <v-row class="mb-n7">
        <v-col>
          <div
            class="d-flex flex-wrap align-center rounded mb-4"
            style="gap: 0.5rem"
            :class="{
              'white': !$vuetify.theme.dark,
              'grey darken-4': $vuetify.theme.dark,
            }"
          >
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="{
                'primary white--text': $route.name == item.route,
              }"
              class="py-2 px-4 rounded pointer text-center col-auto col-sm"
              @click="goToSection(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </v-col>

        <v-col cols="auto">
          <v-btn
            icon
            :to="{ name: 'my-company.branding' }"
          >
            <v-icon style="font-size: 26px">
              mdi-home-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <router-view />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      tab: null,
      items: [
        { title: this.$t('Info'), route: 'my-company.info' },
        { title: this.$t('Business Symbol'), route: 'my-company.business-symbol' },
        { title: this.$t('Shareholders'), route: 'my-company.shareholders' },
        { title: this.$t('Accounting'), route: 'my-company.accounting' },
        { title: this.$t('Attachments'), route: 'my-company.attachment' },
        { title: this.$t('Seasonal & Yearly Form'), route: 'my-company.seasonal-form' },
        { title: this.$t('Residence'), route: 'my-company.residence' },
        { title: this.$t('Social Security'), route: 'my-company.social-security' },
      ],
    }
  },

  computed: {
    ...mapGetters(['authShareholder'])
  },

  mounted() {
    this.$_section_title({ title: 'My Company' })
  },

  methods: {
    goToSection(item) {
      this.$router.push({ name: item.route })
    },

    printInfo() {
      let content = {
        content: '',
        css: ''
      }

      content.content = `
        <div style="width: 100%" class="px-6" class="white">
          <table
            cellspacing="0"
            style="width: 100%; border: 1px solid #aaa; border-radius: 2px; border-bottom: 0px"
          >
            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Name')}:</b> ${this.authShareholder.company.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Type')}:</b> ${this.authShareholder.company.company_type.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Governorate')}:</b> ${this.authShareholder.company.governorate.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('First Bank')}:</b> ${this.authShareholder.company.first_bank.name}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Account Bank')}:</b> ${this.authShareholder.company.account_bank.name}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Business Name Date')}:</b> ${this.authShareholder.company.name_date}</td>
            </tr>
           
           <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Registration Number')}:</b> ${this.authShareholder.company.company_registration_number ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Company Registration Date')}:</b> ${this.$_date_format(this.authShareholder.company.company_registration_date)}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Manager')}:</b> ${this.authShareholder.company.manager ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Phone')}:</b> ${this.authShareholder.company.phone}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Email')}:</b> ${this.authShareholder.company.email ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Website')}:</b> ${this.authShareholder.company.website ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Companies')}:</b> ${this.authShareholder.company.dos_number_in_co ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('Dos Number In Tax Office')}:</b> ${this.authShareholder.company.dos_number_in_tax_office ?? ''}</td>
            </tr>

            <tr>
              <td colspan="2" class="bottom-border pa-2"><b>${this.$t('Dos Number In Companies/General')}:</b> ${this.authShareholder.company.dos_number_in_co_general ?? ''}</td>
            </tr>

            <tr>
              <td class="bottom-border pa-2"><b>${this.$t('SL')}:</b> ${this.authShareholder.company.sl ?? ''}</td>
              <td class="bottom-border pa-2"><b>${this.$t('FCC')}:</b> ${this.authShareholder.company.fcc ?? ''}</td>
            </tr>

            <tr>
              <td colspan="2" class="bottom-border pa-2"><b>${this.$t('Activity')}:</b> ${this.authShareholder.company.activity ?? ''}</td>
            </tr>
          </table>
        </div>
      `

      content.css = `
        .bottom-border {
          border-bottom: 1px solid #aaa
        }
      `

      this.$_manual_print(content)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>